import React, { useState, useEffect } from 'react';
import { Container, Card, Button, TextField, Typography, Paper, CardContent, Box, CardActions, makeStyles, AppBar, Tooltip, Toolbar, IconButton, Menu, MenuItem, Input, InputLabel, Dialog, DialogTitle } from "@material-ui/core";
import queryString from "query-string";
import io from "socket.io-client";
import RandomEnglishWord from "random-english-words";
import { Mic, MicOff, ExitToApp, Visibility, VisibilityOff, Forum, Send, ArrowLeft, ArrowBackIos, ArrowBack, DoneAll, Share, FileCopy } from '@material-ui/icons';
import icon1 from "./icons/icon1.jpg";
import icon2 from "./icons/icon2.jpg";
import icon3 from "./icons/icon3.jpg";
import iconScrew from "./icons/iconScrew.png";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import "./index.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: cyan[700],
    },
    secondary: {
      main: "#ffffff"
    }
  },
});
const useStyle = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 24,
  },
  wrapper: {
    marginTop: "2em",
    marginBottom: "2em",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  selector: {
    cursor: "pointer"
  },
  inactivate: {
    opacity: "0.3"
  },
  icon: {
    border: "solid 0px",
    borderRadius: "50%",
    transition: "opacity 200ms"
  },
  grow: {
    flexGrow: 1,
  },
  chat: {
    minHeight: "16px",
    padding: "6px 12px",
    backgroundColor: "#e6e6e6",
    maxWidth: "calc(100% - 60px)",
    float: "left",
    clear: "both",
    border: "solid 0px",
    borderRadius: "8px"
  },
  myChat: {
    backgroundColor: cyan[700],
    color: "white",
    float: "right",
  },
  systemMessage: {
    textAlign: "center",
    lineHeight: "3rem"
  },
  coloredButton: {
    color: "white"
  }
}))
function Wrapper(props) {
  const classes = useStyle();
  return (<div className={classes.wrapper}>
    {props.children}
  </div>);
}
function Grow(props) {
  const classes = useStyle();
  return (<div className={classes.grow}>
    {props.children}
  </div>);
}
function ColoredButton(props) {
  const classes = useStyle();
  return (<Button {...props} className={classes.coloredButton} />);
}
class App extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(window.location.hash);
    this.state = {
      streamer: query?.streamer ?? null,
      room: query?.room ?? null,
      name: query?.streamer ? "螺絲醒醒監視器" : null,
      icon: query?.streamer ? -1 : null,
      voice: false,
      message: null,
      logs: [{ type: "system_message", message: "連線中" }],
      pined: null,
      debug: false,
      copyDialog: false,
      replyUpdated: null
    };
    this.socket = null;
    this.recognition = null;
    if (window.webkitSpeechRecognition) {
      this.recognition = new window.webkitSpeechRecognition();
      this.recognition.continuous = false;
      this.recognition.interimResults = true;
      this.recognition.maxAlternatives = 1;
      this.recognition.lang = "cmn-Hant-TW";
      this.recognition.onresult = async (event) => {
        const { transcript, confidence } = event?.results?.[(event?.results?.length ?? 1) - 1]?.[0] ?? {};
        const { isFinal } = event?.results?.[(event?.results?.length ?? 1) - 1] ?? {};
        console.log(transcript, confidence, isFinal);
        if (transcript && confidence) {
          if (isFinal) {
            this.setState({ message: "" });
            this.socket.send(JSON.stringify({ type: "message", iconType: this.state.icon, name: this.state.name, room: this.state.room, text: transcript, reliable: (confidence > 0.8) }));
          } else {
            this.setState({ message: transcript });
          }
        }
      }
      this.recognition.onstart = async () => {
        console.log('reco. start');
        this.setState(old => ({ logs: old.logs.concat({ type: "received", message: "辨識開始" }) }));
      };
      this.recognition.onend = async () => {
        this.setState(old => ({ logs: old.logs.concat({ type: "received", message: "辨識結束" }) }));
        if (this.state.voice) {
          this.recognition.start();
        }
      };
      this.recognition.onnomatch = async () => {
        this.setState(old => ({ logs: old.logs.concat({ type: "received", message: "無辨識結果" }) }));
      }
    }
    this.initWebSocket = this.initWebSocket.bind(this);
    if (query?.streamer && query?.room) {
      this.initWebSocket("螺絲醒醒監視器", query?.room, -1);
    }
  }
  initWebSocket(name, room, iconType) {
    let websocket = new WebSocket(`wss://${window.location.host}/reply/${room}/${name}/${iconType}`);
    setInterval(() => websocket.send(JSON.stringify({ type: "ping" })), 1000);
    websocket.onopen = (evt) => { };
    websocket.onclose = (evt) => { this.socket = this.initWebSocket(name, room, iconType) };
    websocket.onmessage = async (evt) => {
      let data = JSON.parse(evt.data);
      switch (data.type) {
        case "reply":
          this.setState(old => ({ logs: old.logs.concat(Object.assign(data, { type: "reply", name: "螺絲醒醒", iconType: -1, message: data.text })), replyUpdated: Date.now() }));
          if (!this.state.streamer) {
            window.scrollTo(0, document.body.scrollHeight);
          }
          break;
        case "message":
          this.setState(old => ({ logs: old.logs.concat(Object.assign(data, { type: "message", message: data.text })) }));
          if (!this.state.streamer) {
            window.scrollTo(0, document.body.scrollHeight);
          }
          break;
        case "received":
          this.setState(old => ({ logs: old.logs.concat({ type: "received", message: data.text }) }));
          if (this.state.debug) {
            window.scrollTo(0, document.body.scrollHeight);
          }
          break;
        case "system_message":
          this.setState(old => ({ logs: old.logs.concat({ type: "system_message", message: data.text }) }));
          if (!this.state.streamer) {
            window.scrollTo(0, document.body.scrollHeight);
          }
          break;
        case "pin":
          this.setState({ pined: Object.assign(data, { type: "reply", name: "螺絲醒醒", iconType: -1, message: data.text }) });
          break;
        case "unpin":
          this.setState({ pined: null });
          break;
      }
    };
    websocket.onerror = (evt) => { console.log(evt) };
    return websocket;
  }
  render() {
    if (!WebSocket) {
      return (<h1>瀏覽器不支援 WebSocket，無法使用聊天室功能</h1>);
    }
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm" style={{ minHeight: "100vh", padding: 0 }}>
          {!this.state.streamer && <React.Fragment>
            {//set room
              this.state.room === null && <React.Fragment>
                <Wrapper>
                  <RoomSwitcher onChange={e => this.setState({ room: e })} />
                </Wrapper>
              </React.Fragment>}
            {//set name or icon
              this.state.room !== null && !(this.state.name !== null && this.state.icon !== null) && <React.Fragment>
                <Wrapper>
                  <NameAndIconSwitcher room={this.state.room} onChange={(e) => {
                    this.setState({ name: e.name, icon: e.icon });
                    this.socket = this.initWebSocket(e.name, this.state.room, e.icon);
                  }} />
                </Wrapper>
              </React.Fragment>}
            {//in room
              (this.state.room !== null && this.state.name !== null && this.state.icon !== null) && <React.Fragment>
                <Paper style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
                  <AppBar position="sticky" style={{ top: 0 }}>
                    <Toolbar>
                      <Tooltip title="離開"><IconButton edge="start" onClick={e => window.location.reload()}><ArrowBack /></IconButton></Tooltip>
                      <Typography variant="h6"> {this.state.room}</Typography>
                      <Grow />
                      {!this.state.debug && <Tooltip title="顯示系統訊息"><IconButton onClick={e => this.setState({ debug: true })} ><VisibilityOff /></IconButton></Tooltip>}
                      {this.state.debug && <Tooltip title="隱藏系統訊息"><IconButton onClick={e => this.setState({ debug: false })} ><Visibility color="secondary" /></IconButton></Tooltip>}
                      {!this.state.voice && <Tooltip title={window.webkitSpeechRecognition ? "開啟語音辨識" : "需使用 Google Chrome 以支援語音辨識"}><IconButton disabled={!window.webkitSpeechRecognition} onClick={e => {
                        this.recognition && this.recognition.start();
                        this.setState({ voice: true });
                      }} ><MicOff /></IconButton></Tooltip>}
                      {this.state.voice && <Tooltip title="關閉語音辨識"><IconButton onClick={e => {
                        this.setState({ voice: false }, () => this.recognition && this.recognition.stop());
                      }}><Mic color="secondary" /></IconButton></Tooltip>}
                      <Tooltip title="邀請朋友加入"><IconButton onClick={() => this.setState({ copyDialog: !this.state.copyDialog })}><Share /></IconButton></Tooltip>
                      <CopyLinkDialog open={this.state.copyDialog} onClose={() => this.setState({ copyDialog: false })} link={`${window.location.protocol}//${window.location.hostname}/#${queryString.stringify({ room: this.state.room })}`} />
                      <Tooltip title="開啟實況用監視器"><IconButton href={`/#${queryString.stringify({ streamer: true, room: this.state.room })}`} target="_blank" ><ExitToApp /></IconButton></Tooltip>
                    </Toolbar>
                  </AppBar>
                  <div style={{ flexGrow: 1 }}>
                    {this.state.pined && <Pined message={this.state.pined?.message} unPin={() => this.socket.send(JSON.stringify({ type: "unpin" }))} />}
                    {this.state.logs.filter(o => this.state.debug || o.type !== "received").map(o => (
                      (o.type === "received" || o.type === "system_message") ?
                        (<SystemMessage message={o.message} />)
                        : (<Message name={o.name} icon={o.iconType} my={o.name === this.state.name} message={o.message} onPin={() => this.socket.send(JSON.stringify(Object.assign(o, { type: "pin", name: "螺絲醒醒", room: this.state.room, iconType: -1, reliable: true })))} />)
                    ))}
                  </div>
                  <div style={{ position: "sticky", bottom: 0, padding: "0.5em", backgroundColor: "white", borderTop: " solid 1px rgba(0,0,0,0.12)" }}>
                    <form onSubmit={async e => {
                      e.preventDefault();
                      const _m = this.state.message;
                      this.setState({ message: "" });
                      this.socket.send(JSON.stringify({ type: "message", iconType: this.state.icon, name: this.state.name, room: this.state.room, text: _m, reliable: true }));
                    }}>
                      <Input
                        id="message"
                        placeholder="輸入訊息"
                        fullWidth
                        value={this.state.message}
                        onChange={e => this.setState({ message: e.target.value })}
                        endAdornment={(
                          <IconButton
                            disabled={!this.state.message}
                            onClick={async e => {
                              const _m = this.state.message;
                              this.setState({ message: "" });
                              this.socket.send(JSON.stringify({ type: "message", iconType: this.state.icon, name: this.state.name, room: this.state.room, text: _m, reliable: true }));
                            }}><Send color={this.state.message && "primary" || "disabled"} /></IconButton>
                        )}
                      />
                    </form>
                  </div>
                </Paper>
              </React.Fragment>}
          </React.Fragment>}
          {this.state.streamer && this.state.room && <React.Fragment>
            <div style={{ minHeight: "100vh" }}>
              {this.state.pined &&
                <Message name={"螺絲醒醒"} icon={-1} message={this.state.pined.message} />
              }
              {this.state.logs.filter(o => o.type === "reply").reverse().map((o, idx) => idx === 0 && (
                <Message key={this.state.replyUpdated} id={this.state.replyUpdated} className="autoFade" name={"螺絲醒醒"} icon={-1} message={o.message} />
              ))}
            </div>
          </React.Fragment>}
        </Container>
      </ThemeProvider>
    );
  }
}
function RoomSwitcher({ onChange }) {
  const [room, setRoom] = useState(RandomEnglishWord());
  const [valid, setValid] = useState(true);
  const classes = useStyle();
  return (
    <form onSubmit={e => {
      e.preventDefault();
      valid && onChange(room);
    }}>
      <Card>
        <CardContent>
          <Typography className={classes.title} color="textSecondary">新建或加入房間</Typography>
          <br />
          <TextField
            id="roomid"
            label="輸入房號"
            value={room}
            error={!valid}
            fullWidth
            onChange={(e) => {
              setRoom(e.target.value);
              setValid(e.target.value?.length > 0);
            }} />
        </CardContent>
        <CardActions>
          <Grow />
          <Button onClick={() => valid && onChange(room)} color="primary" variant="contained" className={classes.coloredButton} >確定</Button>
        </CardActions>
      </Card>
    </form>)
}
function NameAndIconSwitcher({ room, onChange }) {
  const [name, setName] = useState(null);
  const [icon, setIcon] = useState(Math.floor(Math.random() * 3));
  const [valid, setValid] = useState(true);
  const classes = useStyle();
  return (
    <form onSubmit={e => {
      e.preventDefault();
      setValid(name?.length > 0);
      valid && onChange({ name: name, icon: icon });
    }}>
      <Card>
        <CardContent>
          <Typography className={classes.title} color="textSecondary">新建或加入「{room}」</Typography>
          <br />
          <div style={{ display: "grid", gridTemplateColumns: "repeat(3, calc(33% - 2em))", gridGap: "2em", justifyContent: "center" }}>
            {[0, 1, 2].map(i => (
              <div onClick={() => setIcon(i)}>
                <Icon type={i} className={`${classes.selector} ${i !== icon && classes.inactivate}`} />
                <Typography style={{ textAlign: "center" }} color="textSecondary">{i === icon && "選擇頭像"}</Typography>
              </div>))}
          </div>
          <br />
          <TextField
            id="name"
            label="輸入暱稱"
            value={name}
            fullWidth
            error={!valid}
            onChange={(e) => {
              setName(e.target.value);
              setValid(e.target.value?.length > 0);
            }}
          />
        </CardContent>
        <CardActions>
          <Grow />
          <Button onClick={() => {
            setValid(name?.length > 0);
            valid && onChange({ name: name, icon: icon });
          }} color="primary" variant="contained" className={classes.coloredButton} >確定</Button>
        </CardActions>
      </Card>
    </form>)
}
function Icon({ type, className, style }) {
  const classes = useStyle();
  return (
    <img style={Object.assign({ width: "100%", height: "auto" }, style ?? {})} className={`${classes.icon} ${className}`} src={(type === -1 && iconScrew) || (type === 1 && icon2) || (type === 2 && icon3) || icon1} />
  )
}
function Message({ my, message, icon, name, onPin, className }) {
  const classes = useStyle();
  const [menu, setmenu] = useState(false);
  const [anchor, setanchor] = useState(null);
  if (my) {
    return (<React.Fragment>
      <div style={{ display: "flex", gap: "8px", margin: "8px", flexWrap: "nowrap", flexDirection: "row", alignContent: "end" }} className={className}>
        <div style={{ flexGrow: 1 }}>
          <div className={`${classes.chat} ${classes.myChat}`}><Typography>{message}</Typography></div>
        </div>
      </div>
    </React.Fragment>);
  } else {
    return (<React.Fragment>
      <div style={{ display: "flex", gap: "8px", margin: "8px", flexWrap: "nowrap", flexDirection: "row" }} className={className}>
        <div style={{ width: "60px" }}>
          <Icon type={icon} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <div>
            <Typography variant="caption" color={icon === -1 ? "primary" : "textPrimary"}>{icon === -1 && <DoneAll />}{name}</Typography>
          </div>
          <div
            onContextMenu={e => {
              if (icon === -1 && onPin) {
                e.preventDefault();
                setanchor(e.currentTarget);
                setmenu(true);
              }
            }}
            className={`${classes.chat}`}>
            <Typography>{message}</Typography>
          </div>
          <Menu
            id="pin"
            open={menu}
            onClose={() => setmenu(false)}
            anchorEl={anchor}
          >
            <MenuItem onClick={e => {
              setmenu(false);
              onPin();
            }}>釘選訊息</MenuItem>
          </Menu>
        </div>
      </div>
    </React.Fragment >);
  }
}
function SystemMessage({ message }) {
  const classes = useStyle();
  return (<React.Fragment>
    <div className={classes.systemMessage}><Typography variant="caption" color="textSecondary" >{message}</Typography></div>
  </React.Fragment>);
}
function Pined({ message, unPin }) {
  const [menu, setmenu] = useState(false);
  const [anchor, setanchor] = useState(null);
  return (<div
    style={{ position: "sticky", top: "64px", backgroundColor: "#e6e6e6", padding: "6px 12px", textAlign: "center" }}
    onContextMenu={e => {
      e.preventDefault();
      setmenu(true);
      setanchor(e.currentTarget);
    }}
  >
    <Typography color="white">{message}</Typography>
    <Menu
      id="unpin"
      open={menu}
      onClose={() => setmenu(false)}
      anchorEl={anchor}>
      <MenuItem onClick={e => {
        setmenu(false);
        unPin();
      }}>取消釘選</MenuItem>
    </Menu>
  </div>);
}
function CopyLinkDialog(props) {
  const classes = useStyle();
  const { onClose, open, link } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle id="simple-dialog-title">分享連結</DialogTitle>
      <div style={{ padding: "16px" }}>
        <TextField title="URL" value={link} fullWidth />
      </div>
    </Dialog>
  );
}
export default App;
